import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import './App.css'
import Search from './pages/Search';
import About from './pages/About'
import Random from './pages/Random';

const App = () => {

  return (
    <div>
      <Router>
        <Routes>
          <Route index element={<Home />} />
          <Route path='search' element={<Search />} />
          <Route path='random' element={<Random />} />
          <Route path='about' element={<About />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
