import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function App() {
    const [answerFromButtonChoice, setAnswerFromButtonChoice] = useState('');
    const [userInputText, setUserInputText] = useState('');
    const [apiResponse, setApiResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleButtonChoice = (value) => {
        setAnswerFromButtonChoice(value);
        setUserInputText(''); // Clear the user input text when making a new choice
    };

    const handleInputChange = (event) => {
        setUserInputText(event.target.value);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://lust.scathach.id/${answerFromButtonChoice}/search?key=${userInputText}`);
            setApiResponse(response.data.data);
            setError(null);
            console.log(response);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='searchContainer'>
            {(!apiResponse && answerFromButtonChoice === '') && (
                <div className='choices'>
                    <h2>Choose from the following options:</h2>
                    <button onClick={() => handleButtonChoice('pornhub')}>PornHub</button>
                    <button onClick={() => handleButtonChoice('xvideos')}>XVideos</button>
                    <button onClick={() => handleButtonChoice('redtube')}>RedTube</button>
                    <button onClick={() => handleButtonChoice('xhamster')}>Xhamster</button>
                    <button onClick={() => handleButtonChoice('xnxx')}>XNXX</button>
                </div>
            )}
            {answerFromButtonChoice !== '' && !apiResponse && !isLoading && (
                <div className='searchTerm'>
                    <h2>Enter your input:</h2>
                    <input type="text" value={userInputText} onChange={handleInputChange} />
                    <button onClick={handleSubmit}>Submit</button>
                </div>
            )}
            {error && <p>Error fetching data: {error.message}</p>}
            {isLoading ? (
                <div className="spinner">
                    <FontAwesomeIcon icon={faSpinner} spin style={{ width: '50px' }} />
                    <h2>Grabbing videos...</h2>
                </div>
            ) : (
                apiResponse && (
                    <div className='searchResultsContainer'>
                        <h2>Search Results</h2>
                        <div className='searchResults'>
                            {apiResponse.map((item, index) => (
                                <a href={item.link} target='_blank' rel='noopener noreferrer' key={index}>
                                    <div className='searchItems'>
                                        <img src={item.image} alt={item.title} />
                                        <p className='title'>{item.title}</p>
                                        <p className='duration'>{item.duration}</p>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

export default App;
