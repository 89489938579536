import React, { useState, useEffect } from 'react';
import '../questionnaire.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Questionnaire = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [suggestionLink, setSuggestionLink] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [suggestionStatus, setSuggestionStatus] = useState('initial'); // 'initial', 'display', 'another'
  const [clickedGetSuggestion, setClickedGetSuggestion] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInitialScreen, setShowInitialScreen] = useState(true);

  const [answers, setAnswers] = useState({
    question1: '',
    question2: '',
    question3: '',
    question4: '',
    question5: '',
    question6: '',
    question7: '',
  });

  const handleStartOver = () => {
    setSuggestionLink(null);
    setSelectedImage(null);
    setSuggestionStatus('initial');
    setClickedGetSuggestion(false);
    setCurrentQuestion(0);
    setAnswers({
      question1: '',
      question2: '',
      question3: '',
      question4: '',
      question5: '',
      question6: '',
      question7: '',
    });
    setShowInitialScreen(true);
  };



  const questions = [
    {
      text: 'Choose your favourite site',
      desc: 'You can pick a site to choose or click any for random',
      choices: ['PornHub', 'XVideos', 'RedTube', 'Xhamster', 'XNXX', 'Any'],
      condition: () => answers.question1 !== 'Any',
    },
    {
      text: 'What kind of video length are you interested in?',
      desc: 'This will return a video length of your choice',
      choices: ['Less than 5:00', '5:00 - 10:00', '10:00 - 20:00', '20:00+'],
      condition: () => answers.question1 !== 'Any',
    },
    {
      text: 'Is there an orientation you want to explore?',
      desc: 'You can choose your orientation or any',
      choices: ['Gay', 'Lesbian', 'Trans', 'Straight'],
      condition: () => answers.question1 !== 'Any',
    },
    {
      text: 'Are you interested in an ethnicity?',
      desc: 'You can choose an ethnicity or any for a random video',
      choices: ['Black', 'Latina', 'Asian', 'Arab', 'White', 'Any'],
      condition: () => answers.question1 !== 'Any',
    },
    {
      text: 'What age would you like to see?',
      desc: 'You can choose an age or any for a random video',
      choices: ['Teen', 'MILF', 'Mature', 'Granny', 'Any'],
      condition: () => answers.question1 !== 'Any',
    },
    {
      text: 'Are there any fetishes that take your fancy?',
      desc: 'You can choose a fetish or you can skip',
      choices: ['Feet', 'Food', 'BBW', 'Watersports', 'BDSM', 'Voyeurism', 'Cuckold', 'Hentai', 'Skip'],
      condition: () => answers.question1 !== 'Any',
    },
    {
      text: 'Pick a category',
      desc: 'You can choose a category or click any for a random video',
      choices: ['POV', 'Anal', 'Squirting', 'Ebony', 'Masturbation', 'Creampie', 'Amateur', 'Threesome', 'Any'],
      condition: () => answers.question1 !== 'Any',
    },
  ];

  const handleAnswer = (question, answer) => {
    // Additional logic for specific questions
    if (question === 'question1') {
      // For question 1, choose a random answer only if the selected choice is 'Any'
      if (answer === 'Any') {
        const randomIndex = Math.floor(Math.random() * questions[currentQuestion].choices.length);
        answer = questions[currentQuestion].choices[randomIndex];
      }
    } if (question === 'question3') {
      if (answer === 'Straight') {
        answer = '';
      } 
    } if (question === 'question4' || question === 'question5' || question === 'question7') {
      // For questions 3, 4, 5, and 6, leave the answer blank only if the choice is 'Any'
      if (answer === 'Any') {
        answer = '';
      }
    } if (question === 'question6') {
      if (answer === 'Skip') {
        answer = '';
      }
    }

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: answer,
    }));

    // Check if there is a next question
    if (currentQuestion < questions.length - 1 && questions[currentQuestion + 1].condition()) {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    } else {
      // If no next question is found, proceed to submission
      setCurrentQuestion(questions.length); // Set currentQuestion to the last index
    }
  };


  useEffect(() => {
    if (loading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [loading]);
  
  const handleSubmit = async () => {
    try {
      setLoading(true);
      console.log(showSpinner);
  
      if (currentQuestion === questions.length) {
        let apiCall = `https://lust.scathach.id/${answers.question1}/`;
        apiCall += `search?key=${answers.question3}+${answers.question4}+${answers.question5}+${answers.question6}+${answers.question7}`;
  
        const response = await fetch(apiCall);
        const apiData = await response.json();
  
        console.log('API Response:', apiData);
  
        if (Array.isArray(apiData.data)) {
          // Filter videos based on selected duration
          const filteredVideos = apiData.data.filter(video => checkVideoLength(video, answers.question2));
  
          if (filteredVideos.length > 0) {
            const randomIndex = Math.floor(Math.random() * filteredVideos.length);
            const selectedObject = filteredVideos[randomIndex];
  
            console.log('Selected Object:', selectedObject);
  
            const suggestionLink = selectedObject.link;
            const suggestionImage = selectedObject.image;
            const suggestionTitle = selectedObject.title;
  
            setSuggestionLink(suggestionLink);
            setSelectedImage(suggestionImage);
            setSelectedTitle(suggestionTitle);
            setSuggestionStatus('display');
            setClickedGetSuggestion(true);
  
          } else {
            console.warn('No videos match the selected duration.');
          }
        } else {
          console.warn('The "data" property in the API response is not an array.');
        }
      } else {
        console.warn('Cannot submit at this stage. Please answer all questions.');
      }
    } catch (error) {
      console.error('Error fetching suggestion:', error);
    } finally {
      setLoading(false); // Set showSpinner to false once the suggestion is loaded or an error occurs
    }
  };
  
  const checkVideoLength = (video, lengthChoice) => {

    const durationInMinutes = parseInt(video.duration);

    switch (lengthChoice) {
      case 'Less than 5:00':
        return durationInMinutes < 5;
      case '5:00 - 10:00':
        return durationInMinutes >= 5 && durationInMinutes <= 10;
      case '10:00 - 20:00':
        return durationInMinutes > 10 && durationInMinutes <= 20;
      case '20:00+':
        return durationInMinutes > 20;
      default:
        return false;
    }
  };

  return (
    <div className="questionnaire-container">
      {showInitialScreen ? (
        <div className="initialScreen">
          <img src="/images/heading.svg" alt="Background with multiple porn images" />
          <h2>Feeling Overwhelmed by the Infinite Porn Video Options? <br></br>Take the Guesswork Out with Just 7 Simple Choices</h2>
          <button onClick={() => setShowInitialScreen(false)}>Let's Get Started</button>
        </div>
      ) : (
        <div className="question-card">
          {loading ? (
            <div className="spinner">
              <FontAwesomeIcon icon={faSpinner} spin style={{ width: '50px' }} />
              <h2>Grabbing video...</h2>
            </div>
          ) : (
            <>
              {currentQuestion < questions.length && (
                <div>
                  <h1>{questions[currentQuestion].text}</h1>
                  <h2>{questions[currentQuestion].desc}</h2>
                  <div className="choices-container">
                    {questions[currentQuestion].choices.map((choice, index) => (
                      <button key={index} onClick={() => handleAnswer(`question${currentQuestion + 1}`, choice)}>
                        {choice}
                      </button>
                    ))}
                  </div>
                </div>
              )}
  
              {suggestionStatus === 'display' && (
                <div className="linkContainer">
                  <h1>Your recommendation:</h1>
                  <h2>Click the image to go to your video or try another suggestion</h2>
                  <a href={suggestionLink} target="_blank" rel="noopener noreferrer">
                    {selectedTitle}
  
                    {/* Display the suggested image */}
                    {selectedImage && (
                      <div className="linkImage">
                        <img src={selectedImage} alt="Video Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
                      </div>
                    )}
                  </a>
                </div>
              )}
              {currentQuestion === questions.length && (
                !clickedGetSuggestion ? (
                  <div>
                    <h1>Your Suggestion is Ready</h1>
                    <h2>Click the button below to see your suggestion</h2>
                    <button onClick={handleSubmit}>Let's Go</button>
                  </div>
                ) : (
                  <div className="finalButtons">
                    <button className="suggestionButton" onClick={handleSubmit}>Another Suggestion</button>
                    <button style={{ background: '#C9509B' }} className="starOverButton" onClick={() => handleStartOver()}>Start Over</button>
                  </div>
                )
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
  

  
  
};

export default Questionnaire;
