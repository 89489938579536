import React from 'react';
import Nav from '../components/Nav';

const Random = () => {

  return (
    <div className="app">
        <Nav />
    </div>
  );
};

export default Random;
