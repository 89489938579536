import React from 'react';
import Nav from '../components/Nav';
import AboutSection from '../components/AboutSection';

const Search = () => {

  return (
    <div className="app">
        <Nav />
        <AboutSection />
    </div>
  );
};

export default Search;
