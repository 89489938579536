import React from 'react';
import Nav from '../components/Nav';
import SearchSection from '../components/Search';

const Search = () => {

  return (
    <div className="app">
        <Nav />
        <SearchSection />
    </div>
  );
};

export default Search;
