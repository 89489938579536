import React from 'react'

function Footer() {
  return (
    <div className='footer'>
      <div>
        <p>We love feedback, <a href='mailto:wptw.site@hotmail.com'>contact us</a> to let us know what you think</p>
      </div>
    </div>
  )
}

export default Footer