import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MobileNav = (props) => {
  const { closeNav } = props;
  return (
    <div className="mobile-nav">
      <div className="close-button" onClick={closeNav}>
        <span>&times;</span>
      </div>
      <div className='mobileNavMenuLogo'>
      <Link to="/">
            <img src="/images/mobileLogo.svg" alt="what porn to watch logo" />
          </Link>
      </div>
      <div className="mobile-nav-links">
        <Link onClick={closeNav} to="/search">Search</Link>
        {/* <Link onClick={closeNav} to="/random">Random</Link> */}
        <Link onClick={closeNav} to="/about">About WPTW</Link>
      </div>
    </div>
  );
};

const Nav = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const closeMobileNav = () => {
    setIsMobileNavOpen(false);
  };

  return (
    <nav>
      {/* Burger Menu Icon */}
      <div className='mobileNavMain'>
        <div className='mobileNavLogo'>
        <Link to="/">
            <img src="/images/mobileLogo.svg" alt="what porn to watch logo" />
          </Link>
        </div>
        <div className="burger-menu" onClick={toggleMobileNav}>
          <img src="/images/menu.png" alt="burger icon" />
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMobileNavOpen && <MobileNav closeNav={closeMobileNav} />}

      {/* Regular Navigation Links */}
      <div className="nav-links">
        <div className='left'>
          <Link to="/">
            <img src="/images/logo.svg" alt="what porn to watch logo" />
          </Link>
        </div>
        <div className='right'>
          <Link to="/search">Search</Link>
          {/* <Link to="/random">Random</Link> */}
          <Link to="/about">About WPTW</Link>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
