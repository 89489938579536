import React, { useState } from 'react';
import Nav from '../components/Nav';
import Questionnaire from '../components/MovieQuery';
import Footer from '../components/Footer';

const Home = () => {
    const [suggestedLink, setSuggestedLink] = useState(null);

    console.log(suggestedLink);
  
    const handleSuggestionReceived = (link) => {
      setSuggestedLink(link);
    };
  return (
    <div className="app">
        <Nav />
        <Questionnaire onSuggestionReceived={handleSuggestionReceived}/>
        <Footer />
    </div>
  );
};

export default Home;
