import React from 'react'

function AboutSection() {
    return (
        <div className='aboutSection'>
            <div className='aboutContent'>
                <h1>About What Porn to Watch</h1>
                <p>Welcome to What Porn to Watch - your ultimate destination for personalised porn recommendations! Our innovative platform simplifies the daunting task of choosing what porn to watch by guiding you through just 7 simple questions. Say goodbye to endless scrolling and indecision, and hello to the perfect porn for your mood.
                    <br></br>
                    <br></br>
                    At What Porn to Watch, we understand that everyone's tastes are unique. That's why our algorithm tailors each recommendation to your specific preferences, ensuring that you discover porn videos you'll love every time. Whether you're in the mood for POV, Hentai, Creampie, or MILFs, we've got you covered.
                    <br></br>
                    <br></br>
                    Our Porn Video recommendation app isn't like the others, we don't ask you to answer loads of questions and then 'require' your email to provide your results.... we provide you a direct link to your porn video!
                    <br></br>
                    <br></br>
                    It's important to note that while we strive to provide accurate and entertaining recommendations, we don't own any of the content featured on our site. We're simply passionate porn enthusiasts dedicated to making your porn-watching experience as enjoyable as possible.
                    <br></br>
                    <br></br>
                    So why wait? Take the guesswork out of porn night and let What Porn to Watch be your guide. Start discovering your next pleasure adventure today!</p>
            </div>
        </div>
    )
}

export default AboutSection